import { ControllerParams } from 'yoshi-flow-editor-runtime';
import { getSettingsValues, IWixStyleParams } from 'yoshi-flow-editor-runtime/tpa-settings';
import { appName } from '../../../.application.json';
import {
  getOrganizationFullByMsid,
  getOrganizationFullByAppDefAndInstance,
  getPaymentMethods,
} from '../../core/oloApi';
import { AppState, createConfiguredStore } from '../../state/createStore';
import { setOrganizationFull, initApp, setPaymentMethods } from '../../state/session/session.actions';
import {
  setPlatformParams,
  setPlatformParamsSettings,
  setPlatformParamsLabelsStyles,
} from '../../state/platformParams/platformParams.actions';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript';
import { IWidgetController } from '@wix/native-components-infra/dist/src/types/types';
import { Store } from 'redux';
import { extractInstanceData } from '../../core/logic/instanceLogic';
import { getBaseUrlForMappedServices } from '../../core/logic/urlLogic';
import { componentSettings } from './componentSettings';
import uuid from 'uuid';
import WixInstance from '@wix/wixrest-utils/dist/WixInstance';
import {
  Action,
  Address,
  Contact,
  CouponSuccess,
  Dispatch,
  Instance,
  OrderItem,
  LabelsSettings,
} from '@wix/restaurants-client-logic';
import { IWixWindowViewMode } from '@wix/native-components-infra/dist/es/src/types/types';
import { SESSION_STORAGE_KEY } from '../../core/constants';
import { CheckoutStep } from '../../core/types/Checkout';
import _ from 'lodash';
import { loadStoredDataIntoStore } from '../../core/logic/sessionStorageDataHandler';

export interface EnvironmentData {
  language: string;
  store: Store<AppState, Action<any>>;
}

export interface StorageData {
  orderItems?: OrderItem[];
  coupon?: CouponSuccess;
  comment?: string;
  checkoutStep: CheckoutStep;
  contact: Contact;
  dispatch: Dispatch;
  selectedAddressOption: Address;
  timestamp: number;
}

async function getOrganizationFull(
  metaSiteId: string,
  instanceId: string,
  appId: string,
  baseUrlForMappedServices: string,
  language: string,
) {
  let organizationFull;
  // if appId equals to orders app guid (olo-client case) use the new api, o.w- use the old one
  if (!!instanceId && !!appId && appId === WixInstance.ORDERS_APP_ID) {
    organizationFull = await getOrganizationFullByAppDefAndInstance(instanceId, appId, language);
  } else {
    organizationFull = await getOrganizationFullByMsid(metaSiteId, baseUrlForMappedServices);
  }
  return organizationFull;
}

async function setup(flowAPI: ControllerFlowAPI): Promise<EnvironmentData> {
  const language = flowAPI.getSiteLanguage();
  const { metaSiteId, instanceId, appDefId: appId } = extractInstanceFromFlowAPI(flowAPI);
  const baseUrlForMappedServices = getBaseUrlForMappedServices({
    websiteUrl: flowAPI.controllerConfig.wixCodeApi.location.baseUrl,
    isSSR: flowAPI.environment.isSSR,
  });

  const organizationFull = await getOrganizationFull(metaSiteId, instanceId, appId, baseUrlForMappedServices, language);

  const locale = organizationFull.restaurant?.locale || 'en_US';
  const paymentMethods = await getPaymentMethods(appId, instanceId, locale);

  const store = createConfiguredStore(undefined, { flowAPI });

  const data = flowAPI.controllerConfig.platformAPIs.storage.session.getItem(SESSION_STORAGE_KEY);
  loadStoredDataIntoStore(store, data, organizationFull.menu);

  store.dispatch(setPaymentMethods({ paymentMethods }));
  store.dispatch(setOrganizationFull({ organizationFull }));
  store.dispatch(initApp());

  return {
    language,
    store,
  };
}

function extractInstanceFromFlowAPI(flowAPI: ControllerFlowAPI): Instance {
  const signedInstance = flowAPI.controllerConfig.appParams.instance;
  return extractInstanceData(signedInstance);
}

function getViewMode(flowAPI: ControllerFlowAPI): IWixWindowViewMode {
  if (flowAPI.environment.isEditor) {
    return 'Editor';
  } else if (flowAPI.environment.isPreview) {
    return 'Preview';
  } else {
    return 'Site';
  }
}

function getLabelsSettings(styleParams: IWixStyleParams, defaultLabelsSettings?: LabelsSettings): LabelsSettings {
  if (_.isEmpty(styleParams.numbers) && _.isEmpty(styleParams.colors) && defaultLabelsSettings) {
    return defaultLabelsSettings;
  }

  const numbersStyleParams = styleParams?.numbers;
  const iconTypeId = numbersStyleParams['wixorders.icontype'];
  const colorTypeId = numbersStyleParams['wixorders.colortype'];

  const labelsSettings: LabelsSettings = { iconTypeId, colorTypeId };
  const colorsStyleParams = styleParams?.colors;
  if (colorsStyleParams) {
    labelsSettings.iconPrimaryColor = colorsStyleParams['wixorder.iconprimarycolor']?.value;
    labelsSettings.iconSecondaryColor = colorsStyleParams['wixorder.iconsecondarycolor']?.value;
    labelsSettings.iconCustomPrimaryColor = colorsStyleParams['wixorder.iconcustomprimarycolor']?.value;
    labelsSettings.iconCustomSecondaryColor = colorsStyleParams['wixorder.iconcustomsecondarycolor']?.value;
  }

  return labelsSettings;
}

function initializeStoreWithPlatformParams(store: Store<AppState, Action<any>>, flowAPI: ControllerFlowAPI) {
  const publicData = flowAPI.controllerConfig.config.publicData;
  const styleParams = flowAPI.controllerConfig.config.style.styleParams;
  store.dispatch(
    setPlatformParams({
      platformParams: {
        compId: flowAPI.controllerConfig.compId.toString(),
        isMobile: flowAPI.environment.isMobile,
        instance: extractInstanceFromFlowAPI(flowAPI),
        settings: getSettingsValues(publicData, componentSettings),
        isRTL: flowAPI.environment.isRTL,
        viewMode: getViewMode(flowAPI),
        signedInstance: flowAPI.controllerConfig.appParams.instance,
        labelsSettings: getLabelsSettings(styleParams, store.getState().platformParams.labelsSettings),
      },
    }),
  );
}

export function controllerFactory(setupFunction: (flowAPI: ControllerFlowAPI) => Promise<EnvironmentData>) {
  return async function createController({ controllerConfig, flowAPI }: ControllerParams): Promise<IWidgetController> {
    const { setProps } = controllerConfig;
    const { isMobile } = flowAPI.environment;

    const { store, language } = await setupFunction(flowAPI);
    initializeStoreWithPlatformParams(store, flowAPI);

    function dispatch(action: Action<any>) {
      store.dispatch(action);
    }

    if (flowAPI.biLogger) {
      flowAPI.biLogger.util.updateDefaults({
        oloSessionId: uuid.v4(),
        restaurantId: store.getState().session.restaurant.id,
        viewMode: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
        visitor_id: undefined, // reset default values from yoshi
        projectName: undefined,
        appName: undefined,
      });
    }

    store.subscribe(() => {
      setProps({
        appState: store.getState(),
      });
    });

    return {
      async pageReady() {
        setProps({
          appState: store.getState(),
          appName,
          language,
          mobile: isMobile,
          dispatch,
          shouldNotRenderMain:
            flowAPI.environment.isSSR &&
            flowAPI.controllerConfig.wixCodeApi.location.path.find((path) => path === 'checkout'),
          isRTL: flowAPI.environment.isRTL,
          basePath: `${flowAPI.controllerConfig.wixCodeApi.location.baseUrl}${
            flowAPI.controllerConfig.wixCodeApi.site.currentPage.url || ''
          }`,
        });
      },
      updateConfig: ($w, updatedConfig) => {
        const updatedPublicData = updatedConfig.publicData.COMPONENT || {};
        const updatedSettings = getSettingsValues(updatedPublicData, componentSettings);
        const updatedStyles = updatedConfig.style.styleParams || {};

        store.dispatch(
          setPlatformParamsSettings({
            settings: updatedSettings,
          }),
        );
        store.dispatch(
          setPlatformParamsLabelsStyles({
            labelsSettings: getLabelsSettings(updatedStyles),
          }),
        );
      },
    };
  };
}

export default controllerFactory(setup);
