import { Menu, OrderItem, validateOrderItem } from '@wix/restaurants-client-logic';
import moment from 'moment';
import { StorageData } from '../../components/MainPage/controller';
import { setSelectedAddress } from '../../state/addressInformationForm/addressForm.actions';
import { addOrderCouponSuccess, setOrderComment, setOrderItems } from '../../state/cart/cart.actions';
import { setCheckoutStep, setContact, setDispatch } from '../../state/checkout/checkout.actions';

const MAX_MS_TO_USE_STORAGE_DATA = 30 * 60000;

export function loadStoredDataIntoStore(store: any, data: any, menu: Menu) {
  if (data) {
    const {
      orderItems,
      coupon,
      comment,
      checkoutStep,
      contact,
      dispatch,
      selectedAddressOption,
      timestamp,
    }: StorageData = JSON.parse(data);
    const currentTimestamp = moment().valueOf();

    if (timestamp + MAX_MS_TO_USE_STORAGE_DATA > currentTimestamp) {
      if (orderItems) {
        const updatedOrderItems = orderItems
          .map((orderItem) => {
            try {
              const { updatedOrderItem } = validateOrderItem(menu, orderItem);
              return updatedOrderItem;
            } catch (e) {
              if (e.message === 'Item was not found in menu') {
                return undefined;
              } else {
                throw e;
              }
            }
          })
          .filter(Boolean);

        store.dispatch(setOrderItems({ orderItems: updatedOrderItems as OrderItem[] }));
      }

      if (coupon) {
        store.dispatch(addOrderCouponSuccess(coupon));
      }

      if (comment) {
        store.dispatch(setOrderComment({ comment }));
      }

      if (checkoutStep) {
        store.dispatch(setCheckoutStep({ step: checkoutStep }));
      }

      if (contact) {
        store.dispatch(setContact({ contact }));
      }

      if (dispatch) {
        store.dispatch(setDispatch({ dispatch }));
      }

      if (selectedAddressOption) {
        store.dispatch(setSelectedAddress({ address: selectedAddressOption }));
      }
    }
  }
}
